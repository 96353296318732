.browserNavigation > p > a,
.mobileNavigation > div > a,
.mobileNavigation > div > p > a {
    text-decoration: none;
    color: white;
}

.row {
    display: flex;
    flex-direction: row;
}

.browserNavigation {
    width: 300px;
    min-width: min-content;
    height: 100vh;
    background-color: black;
}

.browserNavigationHeader {
    color: white;
    font-size: xxx-large;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
}

.browserNavigationText {
    color: white;
    font-size: x-large;
    text-align: center;
    font-weight: bold;
}

.browserContent {
    width: 100%;
    height: 100vh;
    overflow: scroll;
}

.column {
    display: flex;
    flex-direction: column;
}

.mobileNavigation {
    background-color: black;
    display: flex;
    width: 100%;
    position: fixed;
    justify-content: space-between;
}

.mobileNavigationHeader {
    color: white;
    margin: 1em;
    font-size: larger;
    font-weight: 800;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobileNavigationHeader > svg {
    margin-left: auto;
}

.mobileNavigationText {
    color: white;
    font-size: larger;
    text-align: center;
    font-weight: bold;
}

.mobileContent {
    padding: 62px 0 0 0;
    overflow: scroll;
}

.icons {
    padding: 2em;
    text-align: center;
}

.icons > a {
    padding: 0 1em 0 1em;
}
