.browserContainer {
    text-align: center;
    margin: auto;
    width: 60vw;
    padding-top: 2em;
}

.mobileContainer {
    text-align: center;
    margin: auto;
    width: 90vw;
}

.browserContainer > p,
.mobileContainer > p {
    line-height: 1.5em;
    font-size: 24px;
    text-align: left;
}

.browserContainer > img,
.mobileContainer > img {
    width: 100%;
    height: auto;
}
