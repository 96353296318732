@font-face {
    font-family: 'Swiss 721';
    src: local('Swiss 721'), url('./fonts/Swiss721Roman.woff2') format('woff2'),
        url('./fonts/Swiss721Roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss 721';
    src: local('Swiss 721'), url('./fonts/Swiss721Italic.woff2') format('woff2'),
        url('./fonts/Swiss721Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss 721';
    src: local('Swiss 721'), url('./fonts/Swiss721Heavy.woff2') format('woff2'),
        url('./fonts/Swiss721Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss 721';
    src: local('Swiss 721'),
        url('./fonts/Swiss721HeavyItalic.woff2') format('woff2'),
        url('./fonts/Swiss721HeavyItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Swiss 721', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
